import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

type Weather = {
  date: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
};

const weatherApi = createApi({
  baseQuery: baseQuery,
  reducerPath: "weather",
  endpoints: (build) => ({
    getWeatherForecast: build.query<Weather[], { accessToken: string }>({
      query: ({ accessToken }) => ({
        url: `weatherForecast`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});

export const { useLazyGetWeatherForecastQuery } = weatherApi;

export default weatherApi;

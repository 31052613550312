import React from "react";

const TempLandingPage = () => {
  return (
    <div className="container mx-auto bg-gray-400">
      <div className="grid h-screen place-content-center">
        Fintrack app is under construction
      </div>
    </div>
  );
};

export default TempLandingPage;

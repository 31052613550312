import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseQuery = fetchBaseQuery({
  baseUrl: `https://${
    process.env.NODE_ENV === "development"
      ? "localhost:7299"
      : "fintrack-api-stage.herokuapp.com"
  }`,
  prepareHeaders: (headers, { getState }) => {
    // const { accessToken } = (getState() as RootState).auth;

    // // allow increditbot to set its own header
    // if (accessToken && !headers.has(X_EXTEND_ACCESS_TOKEN)) {
    //   headers.set(X_EXTEND_ACCESS_TOKEN, `${accessToken}`);
    // }

    return headers;
  },
});

import React from "react";
import { Route, Routes } from "react-router-dom";
// import ProtectedRoute from "./components/protectedRoute";
// import Dashboard from "./pages/dashboard/Dashboard";
// import Login from "./pages/login/Login";
import TempLandingPage from "./pages/temp/TempLandingPage";

const App: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path="*" element={<TempLandingPage />} />
        {/* <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute component={Dashboard} />} /> */}
      </Routes>
    </div>
  );
};

export default App;
